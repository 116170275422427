@font-face {
  font-family: 'Billy Signature Slant';
  src:
    local('Billy Signature Slant'),
    url('./fonts/Billy Signature Slant.ttf') format('opentype');
}

@font-face {
  font-family: 'Billy Signature';
  src:
    local('Billy Signature'),
    url('./fonts/Billy Signature.ttf') format('opentype');
}

@font-face {
  font-family: 'Lovely Tropical';
  src:
    local('Lovely Tropical'),
    url('./fonts/Lovely Tropical.ttf') format('opentype');
}

@font-face {
  font-family: 'Tanda Tangan';
  src:
    local('Tanda Tangan'),
    url('./fonts/Tanda Tangan.ttf') format('opentype');
}

@font-face {
  font-family: 'The Signature';
  src:
    local('The Signature'),
    url('./fonts/The Signature.ttf') format('opentype');
}

@font-face {
  font-family: 'Rustling Sound';
  src:
    local('Rustling Sound'),
    url('./fonts/Rustling Sound.ttf') format('opentype');
}

*,
:after,
:before {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  width: 100%;
  font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Ubuntu', 'Roboto', 'Helvetica Neue',
    'Arial', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

// background-color: rgb(0, 118, 14) !important;
//     border-radius: 8px;
//     border: 1px solid rgb(55, 171, 68);
//     color: rgb(255, 255, 255);
//     display: block;
//     font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
//     font-size: 16px;
//     font-weight: normal;
//     font-style: normal;
//     padding: 16px 28px;
//     text-decoration: none;
//     min-width: 30px;
//     text-align: center;
//     direction: ltr;
//     letter-spacing: 0px;

body {
  display: flex;
  flex-direction: column;

  position: relative;
  min-height: 100%;
  height: 100%;

  margin: 0px;
  padding: 0px;

  background: #fff;
  // background: #f6f8fb;

  letter-spacing: 0px;
  touch-action: manipulation;
  text-rendering: optimizelegibility;

  font-size: 0.875rem;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  min-height: 100%;
}
